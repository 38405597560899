import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs"; // Import dayjs
import {
  update,
  create,
  resetSelected,
} from "../../../store/ProductNews/productNewsSlice";
import { showMessage } from "../../../store/Revalu/notificationSlice";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 22,
    boxShadow: "0px 2px 5px #343D4F08",
    borderRadius: 12,
  },
  formWrapper: {
    marginBottom: 13,
    "& .textfield": {
      border: "1px solid " + theme.palette.divider,
      borderRadius: 13,
    },
  },
  label: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
  },
  submitButton: {
    marginLeft: "auto",
    display: "block",
    marginTop: 13,
  },
  datePicker: {
    width: "calc(50% - 10px)",
    marginRight: "0px",
  },
  timeWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
  },
  timeZone: {
    width: "auto !important",
  },
}));

const CreateProductNewsForm = ({
  currentRecord,
  onClose = () => {},
  setSelectedId,
  updateTable = () => {},
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const id = currentRecord?.id;
  const defaultValues = {
    id: currentRecord?.id || "",
    postTitle: currentRecord?.header || "",
    body: currentRecord?.body || "",
    comment: currentRecord?.comment || "",
    subHeaders: [
      {
        header: currentRecord?.subHeader1 || "",
        body: currentRecord?.subBody1 || "",
      },
      {
        header: currentRecord?.subHeader2 || "",
        body: currentRecord?.subBody2 || "",
      },
      {
        header: currentRecord?.subHeader3 || "",
        body: currentRecord?.subBody3 || "",
      },
    ],
    validFrom: currentRecord?.validFrom ? dayjs(currentRecord.validFrom) : null,
    validTo: currentRecord?.validUntil ? dayjs(currentRecord.validUntil) : null,
  };

  const schema = yup.object().shape({
    id: yup.string(),
    postTitle: yup.string().required("Post Title is required"),
    body: yup.string().required("Body is required"),
    comment: yup.string().required("Comment is required"),
    subHeaders: yup.array().of(
      yup.object().shape({
        header: yup.string(),
        body: yup.string(),
      }),
    ),
    validFrom: yup
      .date()
      .nullable()
      .required("Valid From is required")
      .typeError("Invalid Date and Time")
      .min(dayjs().startOf("day"), "Valid From cannot be in the past"),
    validTo: yup
      .date()
      .nullable()
      .required("Valid To is required")
      .typeError("Invalid Date and Time")
      .when("validFrom", (validFrom, schema) =>
        validFrom
          ? schema.min(validFrom, "Valid To cannot be before Valid From")
          : schema,
      ),
  });

  const { control, handleSubmit, formState, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, errors } = formState;

  const { fields } = useFieldArray({
    control,
    name: "subHeaders",
  });

  const onResponse = (res) => {
    if (!res?.status) {
      dispatch(
        showMessage({
          message: `The Product News has been ${id ? "updated" : "created"}`,
        }),
      );
      dispatch(resetSelected());
      reset();
      setSelectedId(undefined);
      updateTable();
      onClose();
    } else if (res?.status === 400) {
      if (
        res?.data &&
        res.data["database exception"]
          ?.toString()
          ?.toLowerCase()
          ?.includes("duplicate")
      ) {
        dispatch(
          showMessage({ message: "ID already exists", variant: "error" }),
        );
      }
    }
  };

  const onSubmit = async (model) => {
    setLoading(true);
    try {
      let res;
      if (id) {
        res = await dispatch(update(id, model));
      } else {
        res = await dispatch(create(model));
      }
      onResponse(res);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Post Title*</label>
              <Controller
                name="postTitle"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    placeholder="Post Title"
                    error={!!errors.postTitle}
                    helperText={errors?.postTitle?.message}
                    fullWidth
                  />
                )}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Body*</label>
              <Controller
                name="body"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    placeholder="Body"
                    error={!!errors.body}
                    helperText={errors?.body?.message}
                    multiline
                    fullWidth
                  />
                )}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Comment*</label>
              <Controller
                name="comment"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    placeholder="Comment"
                    error={!!errors.comment}
                    helperText={errors?.comment?.message}
                    fullWidth
                  />
                )}
              />
            </div>
          </Grid>

          {fields.map((item, index) => (
            <React.Fragment key={item.id}>
              <Grid item xs={12}>
                <div className={classes.formWrapper}>
                  <label className={classes.label}>SubHeader {index + 1}</label>
                  <Controller
                    name={`subHeaders[${index}].header`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        placeholder={`SubHeader ${index + 1}`}
                        fullWidth
                      />
                    )}
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={classes.formWrapper}>
                  <label className={classes.label}>SubBody {index + 1}</label>
                  <Controller
                    name={`subHeaders[${index}].body`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        placeholder={`SubBody ${index + 1}`}
                        multiline
                      />
                    )}
                  />
                </div>
              </Grid>
            </React.Fragment>
          ))}

          <Grid item xs={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Valid From*</label>
              <Controller
                name="validFrom"
                control={control}
                render={({ field }) => (
                  <div className={classes.timeWrapper}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        {...field}
                        onChange={(value) => field.onChange(value)}
                        slotProps={{
                          textField: {
                            placeholder: "DD/MM/YYYY HH:mm [CET]",
                            error: !!errors.validFrom,
                            helperText: errors?.validFrom?.message,
                          },
                        }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              error={!!errors.validFrom}
                              placeholder="DD/MM/YYYY HH:mm [CET]"
                              helperText={errors?.validFrom?.message}
                              fullWidth
                            />
                          );
                        }}
                        error={!!errors.validFrom}
                        placeholder="DD/MM/YYYY HH:mm [CET]"
                        inputFormat="DD/MM/YYYY HH:mm [CET]"
                        minDate={dayjs().startOf("day")}
                        renderCustomHeader={(date, utils, openPicker) => {
                          // Custom header or AM/PM rendering logic can go here if needed
                          <div>rest</div>;
                        }}
                      />
                    </LocalizationProvider>
                    <div className={classes.timeZone}>CET</div>
                  </div>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Valid To*</label>
              <Controller
                name="validTo"
                control={control}
                render={({ field }) => (
                  <div className={classes.timeWrapper}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        {...field}
                        onChange={(value) => field.onChange(value)}
                        slotProps={{
                          textField: {
                            placeholder: "DD/MM/YYYY HH:mm [CET]",
                            error: !!errors.validTo,
                            helperText: errors?.validTo?.message,
                          },
                        }}
                        renderInput={(props) => {
                          return (
                            <TextField
                              {...props}
                              error={!!errors.validTo}
                              helperText={errors?.validTo?.message}
                              placeholder="DD/MM/YYYY HH:mm [CET]"
                              fullWidth
                            />
                          );
                        }}
                        helperText={errors?.validTo?.message}
                        placeholder="DD/MM/YYYY HH:mm [CET]"
                        inputFormat="DD/MM/YYYY HH:mm [CET]"
                        minDate={dayjs().startOf("day")}
                        pmam
                      />
                    </LocalizationProvider>
                    <div className={classes.timeZone}>CET</div>
                  </div>
                )}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isValid || loading}
              className={classes.submitButton}>
              {loading ? "Saving..." : "Save"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default CreateProductNewsForm;
