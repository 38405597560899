import React, { useState, useEffect, useRef } from "react";
import { styles } from "../../../styles/Dashboard/data";
import withStyles from "@mui/styles/withStyles";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress, Button, Chip, Badge } from "@mui/material";
import { USER_STATUS, USER_TIERS } from "../../../store/App/constants";
import CloseIcon from "@mui/icons-material/Close";
import MUIDataTable from "mui-datatables";
import Search from "./Search";
import InviteUserForm from "./InviteUserForm";
import { Dialog, DialogTitle, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ActionButtons from "./actionButtonsRegisteredUsers";
import { styles as dataTableStyles } from "../../../styles/dataTable";
import { withMultipleStyles } from "../../../styles/withMultipleStyles";

import {
  addAPIKey,
  deleteAPIKey,
  getAllRegisteredUsers,
  searchData,
} from "../../../store/RegisteredUser/RegisteredUserSlice";
import useAccountTypes from "../../../hooks/useAccountTypes";
import ExportUsers from "./exportUsers";
import TableActionButton from "../Manufacturer/tableActionButton";
import exportUsers from "./exportUsers";
import { showMessage } from "../../../store/Revalu/notificationSlice";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import ManufacturerDetails from "../EDPManagement/MappedEPDs/manufacturerDetails";
import AddAPIKey from "../EDPManagement/MappedEPDs/addAPIKey";
import { DialogHeader } from "../Common/dialogHeader";
import { ChangeUserTier } from "./changeUserTierDialog";

const AllRegisteredUsers = (props) => {
  const types = useAccountTypes();
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [viewUserTierDialog, setViewUserTierDialog] = useState(false);
  const refreshPage = () => setUpdateData((prev) => !prev);
  const userAccountTypeId = useLocalStorage("userAccountTypeId");
  const [showAddAPIKeyModal, setShowAddAPIKeyModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [idForLinkManufacturer, setIdForLinkManufacturer] = useState(null);

  const { loading, data, searchValue } = useSelector(
    ({ registerUser }) => registerUser.registeredUserAll,
  );

  const { totalElements } = data;

  const handleLinkManufacturer = (id) => {
    setIdForLinkManufacturer(id);
    // console.log("id for link manufacturer", id)
  };

  const handleSelectRow = (row) => {
    setSelectedRow(row);
  };

  // METHODS FOR ADD/REMOVE API KEY
  const handleOpenAddAPIKeyModal = () => {
    setShowAddAPIKeyModal(true);
  };
  const handleCloseAddAPIKeyModal = () => {
    setShowAddAPIKeyModal(false);
  };
  const showChangeUserTierModal = () => {
    setViewUserTierDialog(true);
  };
  const handleAddAPIKey = async ({ apiKey }) => {
    try {
      const userId = selectedRow?.id;
      if (!userId) {
        throw new Error("No user selected!");
      }
      const res = await dispatch(addAPIKey({ apiKey, userId }));
      const { status, data } = res;
      if (Number(status) === 200 && data) {
        dispatch(showMessage({ message: "API key added successfully" }));
        handleCloseAddAPIKeyModal();
      } else {
        dispatch(
          showMessage({
            message: "Error in assigning API key, please try again",
            variant: "error",
          }),
        );
      }
    } catch (err) {
      console.error(err.data);
      dispatch(
        showMessage({
          message: err.data["database exception"],
          variant: "error",
        }),
      );
    }
  };
  const handleDeleteAPIKey = async () => {
    try {
      const userId = selectedRow?.id;
      if (!userId) {
        throw new Error("No user selected!");
      }
      const res = await dispatch(deleteAPIKey({ userId }));
      const { status, data } = res;
      if (Number(status) === 200 && data) {
        dispatch(showMessage({ message: "API key deleted successfully" }));
      } else {
        dispatch(
          showMessage({
            message: "Error in deleting API key, please try again",
            variant: "error",
          }),
        );
      }
    } catch (err) {
      console.error(err);
      dispatch(
        showMessage({
          message: "An internal server error occurred! " + err.message,
          variant: "error",
        }),
      );
    }
  };
  // : METHODS FOR ADD/REMOVE API KEY

  const hideModal = () => {
    setShowModal(false);
    setIdForLinkManufacturer(null);
    // dispatch(resetSelected())
  };

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const getAccountNameFromId = (id) => {
    if (types.length > 0) {
      const resultName = types.filter((t) => t.id == id)[0];
      return resultName.name;
    } else {
      return id;
    }
  };

  const getAccountName = (id) => {
    if (types.length > 0) {
      const resultName = types.filter((t) => t.id == id)[0];

      let color = "primary";
      if (resultName.name[0] === "M") {
        color = "secondary";
      }
      return <Chip label={resultName.name[0]} color={color} size="large" />;
    } else {
      return id;
    }
  };

  const updateTable = () => {
    dispatch(getAllRegisteredUsers({ pageNo, pageSize, searchValue }));
  };

  useEffect(() => {
    updateTable();
  }, [pageNo, pageSize, updateData]);

  // If the search value changes set the page number to the first page
  useEffect(() => {
    setPageNo(0);
    updateTable();
  }, [searchValue]);

  const headers = [
    { name: "User Type", options: { sort: false } },
    { name: "userId", options: { sort: false } },
    { name: "Name", options: { sort: false } },
    { name: "Email", options: { sort: false } },
    { name: "Status", options: { sort: false } },
    { name: "Tier", options: { sort: false } },
    { name: "locked?", options: { sort: false } },
    { name: "Creation Date", options: { sort: false } },
    {
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <ActionButtons
              rowData={value}
              refreshPage={refreshPage}
              type={getAccountNameFromId(userAccountTypeId)}
              handleLinkManufacturer={handleLinkManufacturer}
              handleOpenAddAPIKeyModal={handleOpenAddAPIKeyModal}
              handleDeleteAPIKey={handleDeleteAPIKey}
              onActionMenuOpen={handleSelectRow}
              showChangeUserTierModal={showChangeUserTierModal}
            />
          );
        },
      },
    },
  ];
  const getParsedData = () => {
    return data?.content?.map((m) => [
      getAccountName(m.userAccountTypeId),
      m.id,
      m.firstName + " " + m.lastName,
      m.email,
      USER_STATUS[m.currentUserStatus] || "",
      USER_TIERS[m.user_tier],
      m.isActive ? "" : "Yes",
      new Date(m.createdDate).toLocaleString("en-US"),
      {
        userId: m.id,
        id: m.id,
        email: m.email,
        status: m.currentUserStatus,
        isLocked: m.isActive,
        userTier: m.user_tier,
        manufacturer: m.manufacturer,
        isApiKeyExists: m.isApiKeyExists,
        beta_user_group: m.beta_user_group,
      },
    ]);
  };

  const { classes } = props;

  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    checkbox: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    filterType: "checkbox",
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50],
    page: pageNo,
    onSearchClose: () => {
      dispatch(searchData({ search: "" }));
    },
    customSearchRender: (hideSearch) => {
      return (
        <Search
          classes={classes}
          hideSearch={hideSearch}
          onSearch={searchData}
          placeholder="Search in name"
        />
      );
    },
    onTableChange: (action, state) => {
      const { page, rowsPerPage } = state;
      switch (action) {
        case "changePage":
          setPageNo(page);
          break;
        case "changeRowsPerPage":
          setPageSize(rowsPerPage);
          break;
      }
    },
  };

  return (
    <>
      <div className={classes.pageWrapper}>
        <div className={classes.headerContent}>
          <div className={classes.headerOptions}>
            <div className={classes.headerOptions}>
              <Box display="flex" justifyContent="flex-end" gap="5px">
                {getAccountNameFromId(userAccountTypeId) === "SuperAdmin" && (
                  <ExportUsers />
                )}

                <Button
                  variant="contained"
                  color="primary"
                  onClick={toggleModal}
                  startIcon={<AddCircleIcon style={{ color: "#c4cbda" }} />}>
                  Invite new
                </Button>
              </Box>
            </div>
          </div>
        </div>

        {loading && (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        )}

        <div className={classes.mainrapper}>
          <div className={classes.fieldswrapper}>
            <MUIDataTable
              title="Platform Users"
              columns={headers}
              data={getParsedData()}
              options={{ ...options, count: totalElements }}
              onPageChange={() => {}}
            />
          </div>
        </div>
      </div>
      {Boolean(idForLinkManufacturer) && (
        <Dialog
          open={Boolean(idForLinkManufacturer)}
          maxWidth="lg"
          fullWidth
          onClose={hideModal}
          scroll="body">
          <DialogHeader onClose={hideModal} title={"Manufacturer linking"} />
          <ManufacturerDetails
            option="user"
            onClose={hideModal}
            id={idForLinkManufacturer}
            refreshPage={refreshPage}
          />
        </Dialog>
      )}

      {/* DIALOG FOR ADD API KEY */}
      <Dialog
        open={showAddAPIKeyModal}
        maxWidth="lg"
        fullWidth
        scroll="body"
        onClose={handleCloseAddAPIKeyModal}>
        <DialogHeader
          onClose={handleCloseAddAPIKeyModal}
          title={"Add API Key"}
        />
        <AddAPIKey onAddAPIKey={handleAddAPIKey} loading={loading} />
      </Dialog>
      {/* : DIALOG FOR ADD API KEY */}

      {showModal && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={hideModal}
          open={showModal}>
          <DialogHeader onClose={hideModal} title={"Invite user"} />
          <InviteUserForm onClose={hideModal} />
        </Dialog>
      )}
      {viewUserTierDialog && (
        <ChangeUserTier
          open={viewUserTierDialog}
          setOpen={setViewUserTierDialog}
          selectedRow={selectedRow}
        />
      )}
    </>
  );
};

export default withMultipleStyles(styles, dataTableStyles)(AllRegisteredUsers);
