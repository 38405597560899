import * as React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { styles } from "../../../styles/Dashboard/data";
import withStyles from "@mui/styles/withStyles";
import {
  setSelected,
  deleteAdminUser,
  getAllAdminUser,
} from "../../../store/AdminUser/adminUserSlice";
import { showMessage } from "../../../store/Revalu/notificationSlice";
import { useDispatch, useSelector } from "react-redux";
import AlertDialog from "../../../common/AlertDialog/AlertDialog";
import { setConfirmation } from "../../../store/AlertDialog/alertDialogSlice";
import { setUserBetaAccess } from "../../../store/RegisteredUser/RegisteredUserSlice";

const ITEM_HEIGHT = 48;

function ActionButtons(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alert, setAlert] = React.useState(false);
  const [alertDeleteAPIKey, setAlertDeleteAPIKey] = React.useState(false);
  const [deleteRequest, setDeleteRequest] = React.useState("");
  const open = Boolean(anchorEl);
  const {
    classes,
    rowData,
    onAddAPIKeyClick = () => {},
    handleDeleteAPIKey = () => {},
    showChangeUserTierModal,
  } = props;
  const dispatch = useDispatch();
  const userId = rowData[0];
  const doesApiKeyExists = rowData[7];
  const betaAccess = rowData[9];

  const alertConfirmation = useSelector(
    ({ alert }) => alert.alertSlice.alertConfirm,
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const editHandler = async () => {
    dispatch(setSelected({ id: rowData[0] }));
    handleClose();
  };

  const showAlertDialogDelete = () => {
    setAlert(true);
    setDeleteRequest("USER");
    handleClose();
  };

  const handleShowDeleteAPIKeyAlert = () => {
    setAlertDeleteAPIKey(true);
    setDeleteRequest("API_KEY");
  };
  const handleCloseDeleteAPIKeyAlert = () => {
    setAlertDeleteAPIKey(false);
    setDeleteRequest("");
  };

  const updateBetaAccess = async (access) => {
    try {
      const res = await dispatch(setUserBetaAccess(userId, access));
      if (res === "Done") {
        dispatch(showMessage({ message: "User beta access updated" }));
        handleClose();
        dispatch(getAllAdminUser({}));
      } else {
        dispatch(
          showMessage({
            message: "Error in changing user beta access, please try again",
            variant: "error",
          }),
        );
      }
    } catch (err) {
      console.error(err.data);
      dispatch(
        showMessage({
          message: err,
          variant: "error",
        }),
      );
    }
  };

  const deleteHandler = async () => {
    switch (deleteRequest) {
      case "API_KEY":
        if (alertConfirmation) {
          try {
            handleCancel();
            await handleDeleteAPIKey();
          } catch (ex) {
            console.error(ex);
            dispatch(
              showMessage({
                message: "An internal server error has occurred! " + ex.message,
              }),
            );
          } finally {
            setDeleteRequest("");
            setAlertDeleteAPIKey(false);
          }
        }
        break;
      default:
        if (alertConfirmation == true) {
          try {
            await dispatch(deleteAdminUser(rowData[0]));
            dispatch(showMessage({ message: "Record deleted" }));
          } catch (ex) {
            dispatch(
              showMessage({
                message: ex.message ? ex.messge : ex,
                variant: "error",
              }),
            );
          } finally {
            setDeleteRequest("");
            setAlert(false);
          }
        }
        break;
    }
  };

  const handleCancel = () => {
    dispatch(setConfirmation(false));
    setAlert(false);
    setDeleteRequest("");
  };

  return (
    <div className={classes.ActionButtons}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{
          marginTop: 33,
          maxHeight: ITEM_HEIGHT * 7,
          width: "20ch",
        }}>
        <MenuItem onClick={editHandler}>Edit</MenuItem>
        <MenuItem onClick={showAlertDialogDelete}>Delete</MenuItem>
        {/* ADD/DELETE API KEY */}
        {doesApiKeyExists === "Yes" ? (
          <MenuItem
            onClick={() => {
              handleClose();
              handleShowDeleteAPIKeyAlert();
            }}>
            Delete API Key
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              handleClose();
              onAddAPIKeyClick();
            }}>
            Add API Key
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleClose();
            showChangeUserTierModal();
          }}>
          Change user tier
        </MenuItem>
        {betaAccess == 0 ? (
          <MenuItem
            onClick={() => {
              updateBetaAccess(1);
            }}>
            Enable Beta
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              updateBetaAccess(0);
            }}>
            Disable Beta
          </MenuItem>
        )}

        {/* : ADD/DELETE API KEY */}
      </Menu>

      {alert === true ? (
        <AlertDialog
          title="Delete Admin User"
          message="Are you sure want to delete this admin user?"
          onLoad={deleteHandler()}
          onCancel={handleCancel}
        />
      ) : (
        ""
      )}

      {/* DELETE API KEY DIALOG */}
      {alertDeleteAPIKey && (
        <AlertDialog
          title="Delete API Key"
          message="Are you sure want to delete the API Key?"
          onLoad={deleteHandler()}
          onCancel={handleCloseDeleteAPIKeyAlert}
        />
      )}
      {/* : DELETE API KEY DIALOG */}
    </div>
  );
}
export default withStyles(styles)(ActionButtons);
