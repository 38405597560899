import React, { useState, useEffect } from "react";
import { renderValueAttribute } from "../../../common/Helper/renderValue";
import { styles } from "../../../styles/Dashboard/data";
import { styles as dataTableStyles } from "../../../styles/dataTable";
import {
  getAllPosts,
  setSelected,
  getById,
  deletePost,
} from "../../../store/ProductNews/productNewsSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { showMessage } from "../../../store/Revalu/notificationSlice";
import MUIDataTable from "mui-datatables";
import { withMultipleStyles } from "../../../styles/withMultipleStyles";
import ActionButtons from "./actionButtons";
import CloseIcon from "@mui/icons-material/Close";
import { DialogHeader } from "../Common/dialogHeader";

// redux

//Components
import TableActionButton from "./tableActionButton";
import CreateProductNewsForm from "./createProductNewsForm";
import AttributeDetails from "./attributeDetails";

const ProductNews = (props) => {
  const { classes } = props;

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedIdToView, setSelectedIdToView] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [sortBy, setSortBy] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const dispatch = useDispatch();
  const { data, loading } = useSelector(({ productNews }) => productNews.slice);

  const { totalElements } = data;

  const handleSelect = (id) => {
    dispatch(setSelected({ id }));
    setSelectedId(id);
    setShowAddModal(true);
  };
  const handleViewDetails = (id) => {
    setSelectedIdToView(id);
    setSelectedId(id);
  };
  const handleDelete = async (id) => {
    try {
      setSelectedId(id);
      const res = await dispatch(deletePost(id));
      if (res) {
        dispatch(showMessage({ message: "Record deleted" }));
        updateTable();
      } else {
        dispatch(
          showMessage({
            message: "An unknown error occurred",
            variant: "error",
          }),
        );
      }
    } catch (ex) {
      dispatch(
        showMessage({
          message: ex.message ? ex.messge : ex,
          variant: "error",
        }),
      );
    }
  };
  const handleCloseDetails = () => {
    setSelectedIdToView(null);
  };
  const updateTable = () => {
    dispatch(getAllPosts({ pageNo, pageSize, sortBy }));
  };
  useEffect(() => {
    updateTable();
  }, [pageNo, pageSize, sortBy]);

  useEffect(() => {
    if (selectedId) {
      setCurrentRecord(data?.content?.find((f) => f.id === selectedId));
    }
  }, [selectedId]);

  const handleSortBy = (e, direction) => {
    const fieldNames = {
      "Start date": "validFrom",
      "End date": "validUntil",
    };

    const directions = {
      asc: "ASC",
      desc: "DESC",
    };
    setSortBy({
      fieldName: fieldNames[e],
      direction: directions[direction],
    });
  };

  const headers = [
    {
      name: "Post Id",
      options: {
        sort: false,
        customHeadRender: (columnMeta) => (
          <th
            key={columnMeta.index}
            style={{
              width: "30px",
              backgroundColor: "#fff",
              fontSize: "0.875rem",
              fontWeight: "400",
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }}>
            {columnMeta.name}
          </th>
        ),
      },
    },
    {
      name: "Post Title",
      options: {
        sort: false,
        customHeadRender: (columnMeta) => (
          <th
            key={columnMeta.index}
            style={{
              width: "700px",
              fontWeight: "400",
              fontSize: "0.875rem",
              alignItems: "center",
              backgroundColor: "#fff",
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }}>
            {columnMeta.name}
          </th>
        ),
        customBodyRender: (value) => {
          return <span>{renderValueAttribute(value)}</span>;
        },
      },
    },
    {
      name: "Comments",
      options: {
        sort: false,
        customHeadRender: (columnMeta) => (
          <th
            key={columnMeta.index}
            style={{
              width: "200px",
              fontWeight: "400",
              fontSize: "0.875rem",
              backgroundColor: "#fff",
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }}>
            {columnMeta.name}
          </th>
        ),
        customBodyRender: (value) => {
          return <span>{renderValueAttribute(value)}</span>;
        },
      },
    },
    {
      name: "Start date",
      options: {
        sort: true,
      },
    },
    {
      name: "End date",
      options: {
        sort: true,
      },
    },
    {
      name: "#Reads (incl. int users)",
      options: {
        sort: false,
        customHeadRender: (columnMeta) => (
          <th
            key={columnMeta.index}
            style={{
              width: "100px",
              fontWeight: "400",
              fontSize: "0.875rem",
              backgroundColor: "#fff",
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }}>
            {columnMeta.name}
          </th>
        ),
      },
    },
    {
      name: "Actions",
      options: {
        sort: false,
        customHeadRender: (columnMeta) => (
          <th
            key={columnMeta.index}
            style={{
              width: "50px",
              fontWeight: "400",
              fontSize: "0.875rem",
              backgroundColor: "#fff",
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }}>
            {columnMeta.name}
          </th>
        ),
        customBodyRender: (value) => {
          return (
            <ActionButtons
              onEdit={handleSelect}
              onViewDetails={handleViewDetails}
              rowData={value}
              onDelete={handleDelete}
              setSelectedId={setSelectedId}
            />
          );
        },
      },
    },
  ];
  const hideModal = () => {
    setShowAddModal(false);
  };
  const getParsedData = () => {
    return data?.content?.map((m) => {
      return [
        m?.id,
        m.header,
        m.comment,
        new Date(m.validFrom).toLocaleString("en-US"),
        new Date(m.validUntil).toLocaleString("en-US"),
        m.readCount,
        m,
      ];
    });
  };

  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    checkbox: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    filterType: "checkbox",
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50],
    onColumnSortChange: (col, direction) => handleSortBy(col, direction),
    onSearchClose: () => {
      dispatch(searchData({ search: "" }));
    },
    onTableChange: (action, state) => {
      const { page, rowsPerPage } = state;
      switch (action) {
        case "changePage":
          setPageNo(page);
          break;
        case "changeRowsPerPage":
          setPageSize(rowsPerPage);
          break;
      }
    },
  };

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.headerContent}>
        <div className={classes.headerOptions}>
          <Box
            display="inline-flex"
            justifyContent="flex-end"
            gap="10px"
            flexWrap="wrap">
            <TableActionButton
              onClick={() => {
                setShowAddModal(true);
                setCurrentRecord(null);
              }}
              title="Add a new Post"
            />
          </Box>
        </div>
      </div>
      {loading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}

      <div className={classes.mainrapper}>
        <div className={classes.fieldswrapper}>
          <MUIDataTable
            title="Product News"
            columns={headers}
            data={getParsedData()}
            options={{ ...options, count: data?.totalElements }}
            onPageChange={() => {}}
          />
        </div>
      </div>
      {showAddModal && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={hideModal}
          open={showAddModal}>
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Box>{currentRecord ? "Update" : "Create"} Product News</Box>
              <Box textAlign="right">
                <IconButton onClick={hideModal} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <CreateProductNewsForm
            currentRecord={currentRecord}
            onClose={hideModal}
            updateTable={updateTable}
            setSelectedId={setSelectedId}
          />
        </Dialog>
      )}
      {Boolean(selectedIdToView) && (
        <Dialog
          open={Boolean(selectedIdToView)}
          maxWidth="lg"
          fullWidth
          onClose={handleCloseDetails}
          scroll="body">
          <DialogHeader
            onClose={handleCloseDetails}
            title={"Product News Details"}
          />
          <div className={classes.pageWrapper}>
            <AttributeDetails data={currentRecord} />
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default withMultipleStyles(styles, dataTableStyles)(ProductNews);
