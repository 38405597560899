import React, { useState, useEffect } from "react";
import { styles } from "../../../styles/Dashboard/data";
import withStyles from "@mui/styles/withStyles";
import DataTable_New from "../../../common/DataTable/dataTable_new";
import {
  getAllAdminUser,
  deleteAdminUser,
  resetSelected,
  addAPIKey,
  deleteAPIKey,
} from "../../../store/AdminUser/adminUserSlice";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { Dialog, DialogTitle, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Delete, Edit } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { showMessage } from "../../../store/Revalu/notificationSlice";
import { USER_STATUS } from "../../../store/App/constants";
import CreateAdminUserForm from "../EDPManagement/MappedEPDs/createAdminUserForm";
import AddAPIKey from "../EDPManagement/MappedEPDs/addAPIKey";
import { DialogHeader } from "../Common/dialogHeader";
import { ChangeUserTier } from "./changeUserTierDialog";
import actionButtonsAdminUsers from "./actionButtonsAdminUsers";

const AllAdminUsers = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const { classes } = props;
  const [showAddAPIKeyModal, setShowAddAPIKeyModal] = useState(false);
  const [viewUserTierDialog, setViewUserTierDialog] = useState(false);
  const dispatch = useDispatch();

  const viewAllAdminUsers = useSelector(
    ({ adminUser }) => adminUser.adminUserAll.data,
  );
  const { loading, selected: currentRecord } = useSelector(
    ({ adminUser }) => adminUser.adminUserAll,
  );

  const handleRowClick = (data) => {
    setSelectedRow(data);
  };
  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };
  const hideModal = () => {
    setShowModal(false);
    dispatch(resetSelected());
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = {};
      await dispatch(getAllAdminUser(data));
    };

    fetchData();
  }, [dispatch]);

  const showChangeUserTierModal = () => {
    setViewUserTierDialog(true);
  };

  // METHODS FOR ADD/DELETE API KEY
  const handleOpenAddAPIKeyModal = () => {
    setShowAddAPIKeyModal(true);
  };
  const handleCloseAddAPIKeyModal = () => {
    setShowAddAPIKeyModal(false);
  };
  const handleAddAPIKey = async ({ apiKey }) => {
    try {
      if (!selectedRow) {
        throw new Error("No user selected!");
      }
      const userId = selectedRow[0];
      if (!userId) {
        throw new Error("No user selected!");
      }
      const res = await dispatch(addAPIKey({ apiKey, userId }));
      const { status, data } = res;
      if (Number(status) === 200 && data) {
        dispatch(showMessage({ message: "API key added successfully" }));
        handleCloseAddAPIKeyModal();
      } else {
        dispatch(
          showMessage({
            message: "Error in assigning API key, please try again",
            variant: "error",
          }),
        );
      }
    } catch (err) {
      console.error(err);
      dispatch(
        showMessage({
          message: err.data["database exception"],
          variant: "error",
        }),
      );
    }
  };
  const handleDeleteAPIKey = async () => {
    try {
      if (!selectedRow) {
        throw new Error("No user selected!");
      }
      const userId = selectedRow[0];
      if (!userId) {
        throw new Error("No user selected!");
      }
      const res = await dispatch(deleteAPIKey({ userId }));
      const { status, data } = res;
      if (Number(status) === 200 && data) {
        dispatch(showMessage({ message: "API key deleted successfully" }));
      } else {
        dispatch(
          showMessage({
            message: "Error in deleting API key, please try again",
            variant: "error",
          }),
        );
      }
    } catch (err) {
      console.error(err);
      dispatch(
        showMessage({
          message: err.message,
          variant: "error",
        }),
      );
    }
  };
  // : METHODS FOR ADD/DELETE API KEY

  const headings = [
    "ID",
    "User ID",
    "First Name",
    "Last Name",
    "Email",
    "Status",
    "Active?",
    "API",
    "User Tier",
  ];

  const rowsData = viewAllAdminUsers.map((item) => {
    return [
      item.id,
      item.id,
      item.firstName,
      item.lastName,
      item.email,
      USER_STATUS[item.currentUserStatus] || "",
      item.isActive ? "Yes" : "No",
      item.isApiKeyExists !== null ? "Yes" : "", // index = 7
      item.user_tier,
      item.beta_user_group,
    ];
  });

  return (
    <>
      <div className={classes.pageWrapper}>
        <h1 className="title">Admin Users</h1>

        {loading ? (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        ) : null}
        <div className={classes.headerContent}>
          <div className={classes.headerOptions}>
            <Button
              style={{ marginLeft: 13 }}
              variant="contained"
              color="primary"
              onClick={toggleModal}
              startIcon={<AddCircleIcon style={{ color: "#c4cbda" }} />}>
              Add new
            </Button>
          </div>
        </div>
        <DataTable_New
          hideColumnsIndexes={[0, 9]}
          headings={headings}
          rowsData={rowsData}
          allowActionButtons
          onRowClick={handleRowClick}
          ActionButtons={actionButtonsAdminUsers}
          actionButtonsProps={{
            onAddAPIKeyClick: handleOpenAddAPIKeyModal,
            handleDeleteAPIKey: handleDeleteAPIKey,
            showChangeUserTierModal: showChangeUserTierModal,
          }}
        />
      </div>

      {/* DIALOG FOR ADD API KEY */}
      <Dialog
        open={showAddAPIKeyModal}
        maxWidth="lg"
        fullWidth
        scroll="body"
        onClose={handleCloseAddAPIKeyModal}>
        <DialogHeader
          onClose={handleCloseAddAPIKeyModal}
          title={"Add API Key"}
        />
        <AddAPIKey onAddAPIKey={handleAddAPIKey} loading={loading} />
      </Dialog>
      {/* : DIALOG FOR ADD API KEY */}
      {viewUserTierDialog && (
        <ChangeUserTier
          open={viewUserTierDialog}
          setOpen={setViewUserTierDialog}
          selectedRow={selectedRow}
        />
      )}

      {(showModal || currentRecord) && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={hideModal}
          open={showModal || currentRecord}>
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Box>
                {Boolean(currentRecord) ? "Update" : "Create"} Admin User
              </Box>
              <Box textAlign="right">
                <IconButton onClick={hideModal} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <CreateAdminUserForm
            currentRecord={currentRecord}
            onClose={hideModal}
          />
        </Dialog>
      )}
    </>
  );
};

export default withStyles(styles)(AllAdminUsers);
